import { ReactNode } from 'react';
import Image from 'next/image';
import { useMediaQuery, useQueryErrorToast } from '@/utils/hooks';
import { GetLoginImagesReturn } from '@/utils/images';
import { Box, Container, SxProps } from '@mui/material';

export interface LoginLayoutProps {
  children: ReactNode;
  images?: GetLoginImagesReturn;
  imageType?: keyof GetLoginImagesReturn;
  sx?: SxProps;
  secondaryChildren?: ReactNode;
  classes?: { [key in 'outerContainer' | 'innerContainer']?: SxProps };
}

const LoginLayout = ({
  children,
  images,
  imageType = 'waves',
  sx = {},
  secondaryChildren,
  classes,
}: LoginLayoutProps) => {
  useQueryErrorToast();
  const isMobile = useMediaQuery('sm');
  return (
    <Box sx={{ minWidth: '100vw', minHeight: '100dvh', ...classes?.outerContainer }}>
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '60% 40%' },
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          ...classes?.outerContainer,
        }}
      >
        <Box
          component={'main'}
          sx={{
            scrollSnapAlign: { xs: 'start', md: 'unset' },
            width: 'inherit',
            ...sx,
          }}
        >
          {children}
        </Box>
        {secondaryChildren}
      </Container>
      {!isMobile && <BackgroundImage image={images[imageType]} />}
    </Box>
  );
};

export const BackgroundImage = ({ image, position = 'right', rotate = true, sx = {} }) => {
  // remove this properties to make Next/image work with fill
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height, ...rest } = image;
  return (
    <Box
      id={'bg-image'}
      sx={{
        position: 'absolute',
        inset: {
          xs: `0 ${position === 'left' ? '25%' : 0} 0 ${position === 'right' ? '25%' : 0}`,
          md: `0 ${position === 'left' ? '55%' : 0} 0 ${position === 'right' ? '55%' : 0}`,
        },
        zIndex: -1,
        opacity: 0.6,
        ...(rotate && { transform: 'rotate(180deg)' }),
        '& > img': {
          WebkitMaskImage: `-webkit-gradient(linear, ${position} top, ${position} bottom, from(rgba(0,0,0,1) 80&), to(rgba(0,0,0,0)))`,
          maskImage: `linear-gradient(to ${position}, rgba(0,0,0,1) 80%, rgba(0,0,0,0))`,
        },
        ...sx,
      }}
    >
      <Image
        fill
        alt={''}
        sizes="(max-width: 600px) 25vw, 55vw"
        placeholder={'blur'}
        priority
        {...rest}
      />
    </Box>
  );
};

export default LoginLayout;
