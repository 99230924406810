import { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth';
import { useRouter } from 'next/router';
import { getPlaiceholder } from 'plaiceholder';
import SEO from '@/components/Seo/Seo';
import { env } from '@/config/envs';
import LogIn from '@/containers/Login';
import { GetLoginImagesReturn, getLoginImages } from '@/utils/images';
import { getDefaultOpenGraph } from '@/utils/seo';
import { authOptions } from '../api/auth/[...nextauth]';

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const images = await getLoginImages(getPlaiceholder);
  const session = await getServerSession(req, res, authOptions);
  if (session?.user) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    };
  }
  return {
    props: {
      images,
    },
  };
};

const AppLogin = ({ images }: { images: GetLoginImagesReturn }) => {
  const { query } = useRouter();
  const isRegister = query?.hasOwnProperty('register');
  return (
    <>
      <SEO
        title={isRegister ? 'Sign up' : 'Sign in'}
        canonical={
          isRegister
            ? `${env.NEXT_PUBLIC_DOMAIN}/login?register`
            : `${env.NEXT_PUBLIC_DOMAIN}/login`
        }
        openGraph={getDefaultOpenGraph({
          title: isRegister ? 'Sign up @ Smat' : 'Sign in @ Smat',
          url: isRegister ? '/login?register' : '/login',
        })}
      />
      <LogIn images={images} />
    </>
  );
};

AppLogin.public = true;

export default AppLogin;
