import { useEffect, useRef } from 'react';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import MobileAppLinks from '@/components/FooterHomePage/MobileAppLinks';
import LoginForm from '@/components/Forms/Login';
import { SmatIsoLogo } from '@/components/Logos/Logos';
import { Subtitle, Title } from '@/components/Typography';
import { SESSION_STORAGE_KEYS } from '@/config/Constants';
import { env } from '@/config/envs';
import LoginLayout from '@/layouts/login.layout';
import { REMOVE_SCROLLBAR } from '@/themes/customizations';
import { isLoadedInsideIframe } from '@/utils';
import { useToggle } from '@/utils/hooks';
import { GetLoginImagesReturn } from '@/utils/images';
import { Box, CircularProgress, Fade, Stack } from '@mui/material';
import storage from '../../utils/storage';

const LogIn = ({ images }: { images: GetLoginImagesReturn }) => {
  const { on: isNewUser, toggle: setIsNewUser } = useToggle();
  const {
    asPath,
    query: { idpIntentId, idpIntentToken, sessionId, sessionToken, picture, ...query },
    replace,
    isReady,
  } = useRouter();

  const oidcRef = useRef(0);

  const isAuthPopup = typeof window !== 'undefined' && window?.opener !== null;
  const isOidpLoginCallback = idpIntentId && idpIntentToken && sessionId && sessionToken;

  useEffect(() => {
    (async () => {
      if (isOidpLoginCallback && oidcRef.current === 0) {
        replace(
          {
            query: { loading: true },
          },
          undefined,
          { shallow: true }
        );
        const cbUrl = storage.get<string>(SESSION_STORAGE_KEYS.idpcb) as string;
        const fromIframeOrPopup = isLoadedInsideIframe() || window.opener !== null;
        try {
          oidcRef.current += 1;
          const result = await signIn('credentials', {
            idpIntentId,
            idpIntentToken,
            sessionId,
            sessionToken,
            picture,
            callbackUrl: cbUrl || '/',
            ...(fromIframeOrPopup && { redirect: false }),
          });

          storage.delete(SESSION_STORAGE_KEYS.idpcb);
          // If the user is loggin
          if (result?.status === 200) {
            // TODO! aca debería solicitarle via post message al padre del iframe que redirija, total usuario ya esta logeado
            const url = env.NEXT_PUBLIC_DOMAIN;
            if (window.opener.parent) {
              // TODO! CHECK if this works on webflow iframed... the goal should be to redirect the user on the top
              window.opener.parent.postMessage({ type: 'NAVIGATE', url }, '*');
            } else {
              window.opener.postMessage({ type: 'NAVIGATE', url }, '*');
            }
            window.focus();
            window.close();
          }
        } catch (e) {
          console.error('ERROR', e);
        }
      }
    })();
  }, [isOidpLoginCallback]);

  const isRegister = query.hasOwnProperty('register');

  useEffect(() => {
    if (!isRegister) {
      setIsNewUser(false);
    }
  }, [isRegister]);

  useEffect(() => {
    if (isReady && isLoadedInsideIframe() && !isAuthPopup) {
      function requestParentNavigation(url) {
        window.parent.postMessage({ type: 'NAVIGATE', url: url }, '*');
      }
      // Required for logging in from the iframe in smat.io landing page with credentials flow.
      requestParentNavigation(env.NEXT_PUBLIC_DOMAIN);
    }
  }, []);

  return (
    <LoginLayout
      images={images}
      sx={{
        maxHeight: '100dvh',
        ...(!isNewUser && {
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        overflow: 'scroll',
        ...REMOVE_SCROLLBAR,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
          height: '100%',
          width: 'inherit',
          px: { xs: '1rem', md: '2rem' },
        }}
      >
        <Box sx={{ flex: 1 }} />
        <SmatIsoLogo
          images={images}
          sx={{
            filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.35))',
          }}
        />
        <Stack
          spacing={isNewUser ? 2 : 4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: {
              xs: '256px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <Box
              component={'h1'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'unset',
              }}
            >
              <Title component={'span'}>Welcome to</Title>{' '}
              <Title component={'span'} color={'primary.main'}>
                Smat Platform
              </Title>
            </Box>
          </Box>
          {isOidpLoginCallback || query.loading || isAuthPopup ? (
            <Authenticating />
          ) : (
            <LoginForm callbackUrl={asPath} />
          )}
        </Stack>
        <Stack sx={{ flex: 1, placeContent: { xs: 'center', md: 'flex-end' }, mb: '2rem' }}>
          <MobileAppLinks />
        </Stack>
      </Box>
    </LoginLayout>
  );
};

const Authenticating = () => {
  return (
    <Fade in timeout={1500}>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '256px',
        }}
      >
        <CircularProgress id={'loader'} />
        <Fade in timeout={4500}>
          <Stack display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Subtitle>Authenticating</Subtitle>
            <Subtitle>please wait ...</Subtitle>
          </Stack>
        </Fade>
      </Stack>
    </Fade>
  );
};

export default LogIn;
