import { NextSeo } from 'next-seo';

const defaultDescription =
  'Smat is an investment platform that connects deal sponsors with wealth managers, allowing investors to subscribe financial products with low intermediary costs.';

const SEO: React.FC<React.ComponentProps<typeof NextSeo>> = ({
  title,
  description = defaultDescription,
  ...rest
}) => (
  <NextSeo
    title={
      title
        ? `${title} | SMAT The Alternative Investment Network`
        : 'SMAT | The Alternative Investment Network'
    }
    description={description}
    {...rest}
  />
);

export default SEO;
