import { ComponentProps } from 'react';
import { NextSeo } from 'next-seo';
import { env } from '@/config/envs';
import { isHttpAbsoluteUrl } from '.';

export const getDefaultOpenGraph = ({
  images = [],
  url,
  ...config
}: Partial<ComponentProps<typeof NextSeo>['openGraph']> = {}): Partial<
  ComponentProps<typeof NextSeo>['openGraph']
> => {
  const isAbsolute = isHttpAbsoluteUrl(url);
  return {
    title: `Smat: The Alternative Investment Network`,
    description:
      'Smat is an investment platform that connects deal sponsors with wealth managers, allowing investors to subscribe financial products with low intermediary costs.',
    images: [
      ...images,
      {
        url: 'https://cdn.beta.smat.io/assets/v2/smat-og.png',
        width: 1600,
        height: 900,
        alt: 'Smat logo',
      },
    ],
    locale: 'en',
    url: isAbsolute
      ? url
      : `${env.NEXT_PUBLIC_DOMAIN}/${url?.startsWith('/') ? url.slice(1) : url}`,
    type: 'website',
    siteName: 'Smat web application',
    ...config,
  };
};
